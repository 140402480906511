import {APP_PREFIX_PATH} from "../AppConfig";
import React from "react";

export const uas = [
    {
        key: "administration-ua",
        path: `${APP_PREFIX_PATH}/ua`,
        component: React.lazy(() => import("views/app-views/ua")),
        meta: {
            permission: "LIST_UNITE_ADMINISTRATIVE"
        }
    },

    {
        key: "administration-ua-add",
        path: `${APP_PREFIX_PATH}/ua/add/`,
        component: React.lazy(() => import("views/app-views/ua/add")),
        meta: {
            permission: "ADD_UNITE_ADMINISTRATIVE"
        }
    },

    {
        key: "administration-ua-edit",
        path: `${APP_PREFIX_PATH}/ua/add/:code`,
        component: React.lazy(() => import("views/app-views/ua/add")),
        meta: {
            permission: "EDIT_UNITE_ADMINISTRATIVE"
        }
    }
]