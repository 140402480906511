import React, {useEffect} from 'react';
import {onBlankLayout} from 'store/slices/themeSlice';
import {useDispatch} from 'react-redux';
import {Navigate} from "react-router-dom";
import {usePermissions} from "../context/PermissionsContext";

const AppRoute = ({component: Component, routeKey, blankLayout, meta, ...props}) => {

    const dispatch = useDispatch()

    useEffect(() => {
        const isBlank = !!blankLayout
        dispatch(onBlankLayout(isBlank))

    }, [blankLayout])

    const {hasPermission} = usePermissions();
    const {permission} = props || {};

    if (permission && !hasPermission(permission)) {
        return <Navigate to="/" replace/>;
    }

    return (
        <Component {...props} />
    )
}

export default AppRoute