import React, {lazy, Suspense, memo, useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {ConfigProvider} from 'antd';
import Loading from 'components/shared-components/Loading';
import {lightTheme, darkTheme} from 'configs/ThemeConfig';
import {resources} from 'lang';
import useBodyClass from 'utils/hooks/useBodyClass';
import Routes from 'routes';
import fetchInterceptor from "../auth/FetchInterceptor";
import {setCurrentUser} from "../store/slices/authSlice";
import {PermissionsProvider} from "../context/PermissionsContext";
import {usePermify} from "@permify/react-role"; // Import your action to fetch currentUser

const AppLayout = lazy(() => import('./AppLayout'));
const AuthLayout = lazy(() => import('./AuthLayout'));

const Layouts = () => {
    const dispatch = useDispatch();
    const { setUser } = usePermify();

    const [userDataFetched, setUserDataFetched] = useState(false);

    useEffect(() => {
        fetchInterceptor({
            method: "GET",
            url: "/currentUser",
        }).then((response) => {
            dispatch(setCurrentUser(response))

            setUser({
                id: response.code,
                roles: response.roles,
                permissions: response.permissions
            })
        }).finally(() => {
            setUserDataFetched(true);
        })
    }, [dispatch]);

    const token = useSelector(state => state.auth.token);
    const blankLayout = useSelector(state => state.theme.blankLayout);
    const Layout = token && !blankLayout ? AppLayout : AuthLayout;

    const locale = useSelector(state => state.theme.locale);
    const direction = useSelector(state => state.theme.direction);
    const currentTheme = useSelector(state => state.theme.currentTheme);
    const currentAppLocale = resources[locale];

    useBodyClass(`dir-${direction}`);

    const themeConfig = currentTheme === 'light' ? {...lightTheme} : {...darkTheme};

    return (
        <ConfigProvider theme={themeConfig} direction={direction} locale={currentAppLocale.antd}>
            <Suspense fallback={!userDataFetched ? <Loading cover="content"/> : null}>
                {userDataFetched && (
                    <PermissionsProvider>
                        <Layout>
                            <Routes/>
                        </Layout>
                    </PermissionsProvider>
                )}
            </Suspense>
        </ConfigProvider>
    );
};

export default memo(Layouts);
