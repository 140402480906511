import {APP_PREFIX_PATH} from "../AppConfig";
import React from "react";

export const personnels = [
    {
        key: "administration-personnel",
        path: `${APP_PREFIX_PATH}/personnel`,
        component: React.lazy(() => import("views/app-views/personnel")),
        meta: {
            permission: "LIST_PERSONNEL"
        }
    },

    {
        key: "administration-personnel-add",
        path: `${APP_PREFIX_PATH}/personnel/add`,
        component: React.lazy(() => import("views/app-views/personnel/add")),
        meta: {
            permission: "ADD_PERSONNEL"
        }
    },

    {
        key: "administration-personnel-edit",
        path: `${APP_PREFIX_PATH}/personnel/add/:code`,
        component: React.lazy(() => import("views/app-views/personnel/add")),
        meta: {
            permission: "EDIT_PERSONNEL"
        }
    },

    {
        key: "administration-personnel-show",
        path: `${APP_PREFIX_PATH}/personnel/:code/show`,
        component: React.lazy(() => import("views/app-views/personnel/show")),
        meta: {
            permission: "VIEW_PERSONNEL"
        }
    }
]