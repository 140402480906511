import {APP_PREFIX_PATH} from "../AppConfig";
import React from "react";

export const roles = [
    {
        key: "roles",
        path: `${APP_PREFIX_PATH}/roles`,
        component: React.lazy(() => import("views/app-views/roles/index")),
        meta: {
            permission: "LIST_ROLE",
        },
    },
    {
        key: "roles-add",
        path: `${APP_PREFIX_PATH}/roles/add`,
        component: React.lazy(() => import("views/app-views/roles/add")),
        meta: {
            permission: "ADD_ROLE"
        }
    },
    {
        key: "roles-edit",
        path: `${APP_PREFIX_PATH}/roles/:id/edit`,
        component: React.lazy(() => import("views/app-views/roles/edit")),
        meta: {
            permission: "EDIT_ROLE"
        }
    },
]