import {APP_PREFIX_PATH} from "../AppConfig";
import React from "react";

export const fonctions = [
    {
        key: "administration-fonction",
        path: `${APP_PREFIX_PATH}/fonction`,
        component: React.lazy(() => import("views/app-views/fonction")),
        meta: {
            permission: "LIST_FONCTION"
        }
    },

    {
        key: "administration-ua-add",
        path: `${APP_PREFIX_PATH}/fonction/add/`,
        component: React.lazy(() => import("views/app-views/fonction/add")),
        meta: {
            permission: "ADD_FONCTION"
        }
    },

    {
        key: "administration-ua-edit",
        path: `${APP_PREFIX_PATH}/fonction/add/:code`,
        component: React.lazy(() => import("views/app-views/fonction/add")),
        meta: {
            permission: "EDIT_FONCTION"
        }
    }
]