import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
	create: null,
	activeStep: 0,
	detailsCourrier: null,
	expediteur: null,
	correspondants: [],
}

export const courrierSlice = createSlice({
	name: 'courrier',
	initialState,
	reducers: {
        setCreate: (state, action) => {
			state.create = action.payload
		},
		next: (state) => {
			state.activeStep = state.activeStep + 1
		},
		setDetailsCourrier: (state, action) => {
			state.detailsCourrier = action.payload
		},
		setExpediteur: (state, action) => {
			state.expediteur = action.payload
		},
		addCorrespondant: (state, action) => {
			state.correspondants.push(action.payload)
		},
		removeCorrespondant: (state, action) => {
			console.log(action.payload, state.correspondants)
			state.correspondants.filter((element) => element !== action.payload)
		},
		// addPieceJointe: (state, action) => {
		// 	state.piecesJointes.push(action.payload)
		// },
		// removePieceJointe: (state, action) => {
		// 	state.piecesJointes.filter((element) => element !== action.payload)
		// },
		previous: (state) => {
			state.activeStep = state.activeStep - 1
		},
		resetCourrier: (state) => {
			Object.assign(state, initialState)
		}
	},
})

export const { 
    setCreate,
	setDetailsCourrier,
	setExpediteur,
	addCorrespondant,
	removeCorrespondant,
	addPieceJointe,
	removePieceJointe,
	resetCourrier,
	next,
	previous
} = courrierSlice.actions

export default courrierSlice.reducer