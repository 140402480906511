import {APP_PREFIX_PATH} from "../AppConfig";
import React from "react";

export const sections = [
    {
        key: "administration-section",
        path: `${APP_PREFIX_PATH}/section`,
        component: React.lazy(() => import("views/app-views/section")),
        meta: {
            permission: "LIST_SECTION"
        }
    },

    {
        key: "administration-section-add",
        path: `${APP_PREFIX_PATH}/section/add/`,
        component: React.lazy(() => import("views/app-views/section/add")),
        meta: {
            permission: "ADD_SECTION"
        }
    },

    {
        key: "administration-section-edit",
        path: `${APP_PREFIX_PATH}/section/add/:code`,
        component: React.lazy(() => import("views/app-views/section/add")),
        meta: {
            permission: "EDIT_SECTION"
        }
    }
]