import {APP_PREFIX_PATH} from "../AppConfig";
import React from "react";

export const correspondants = [
    {
        key: "administration-correspondant",
        path: `${APP_PREFIX_PATH}/correspondant`,
        component: React.lazy(() => import("views/app-views/correspondant")),
        meta: {
            permission: "LIST_CORRESPONDANT"
        }
    },

    {
        key: "administration-correspondant-add",
        path: `${APP_PREFIX_PATH}/correspondant/add/`,
        component: React.lazy(() => import("views/app-views/correspondant/add")),
        meta: {
            permission: "ADD_CORRESPONDANT"
        }
    },

    {
        key: "administration-correspondant-edit",
        path: `${APP_PREFIX_PATH}/correspondant/add/:code`,
        component: React.lazy(() => import("views/app-views/correspondant/add")),
        meta: {
            permission: "EDIT_CORRESPONDANT"
        }
    }
]