// PermissionsContext.js
import React, { createContext, useContext } from 'react';
import {useSelector} from "react-redux";

// Create the context
const PermissionsContext = createContext();

// Provider component
export const PermissionsProvider = ({ children }) => {
    let {currentUser} = useSelector((state) => state.auth);

    const permissions = currentUser?.permissions

    const hasPermission = (permission) => {
        return permissions.includes(permission);
    };

    return (
        <PermissionsContext.Provider value={{ hasPermission }}>
            {children}
        </PermissionsContext.Provider>
    );
};

// Custom hook to use the context
export const usePermissions = () => {
    const context = useContext(PermissionsContext);
    if (!context) {
        throw new Error('usePermissions must be used within a PermissionsProvider');
    }
    return context;
};
