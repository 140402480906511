import {APP_PREFIX_PATH} from "../AppConfig";
import React from "react";

export const organigrammes = [
    {
        key: "administration-organigrammes",
        path: `${APP_PREFIX_PATH}/organigrammes`,
        component: React.lazy(() => import("views/app-views/organigramme")),
        meta: {
            permission: "LIST_ORGANIGRAMME"
        }
    },
    {
        key: "administration-organigramme-uas",
        path: `${APP_PREFIX_PATH}/organigramme/:code/uas`,
        component: React.lazy(() => import("views/app-views/organigramme/uas")),
        meta: {
            permission: "CONFIGURE_UA_ORGANIGRAMME"
        }
    },
]