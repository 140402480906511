import {APP_PREFIX_PATH} from "../AppConfig";
import React from "react";

export const archivage = [
    {
        key: "archivage",
        path: `${APP_PREFIX_PATH}/archivage`,
        component: React.lazy(() => import("views/app-views/archivage")),
    },

    {
        key: "archivage-code",
        path: `${APP_PREFIX_PATH}/archivage/:code`,
        component: React.lazy(() => import("views/app-views/archivage")),
    },
]